<template>
  <div class="w-full max-w-xl py-20 bg-white flex flex-col space-y-20">
    <button class="flex items-center cursor-pointer" @click="$router.back()">
      <i aria-hidden="true" class="fas fa-chevron-left text-sm" />
      <p class="mx-6">
        {{ $t('rewards.reward_confirmation.edit_selection') }}
      </p>
    </button>

    <h2>
      {{ $t('rewards.reward_confirmation.title') }}
    </h2>

    <Card v-if="basket.length > 0" class="p-30 space-y-20">
      <div v-if="redeemingMember.isDependent" class="mb-20">
        <p>
          {{
            $t('rewards.reward_confirmation.redeeming_for', [
              redeemingMember.name,
            ])
          }}
        </p>
      </div>

      <ItemBasket
        v-for="item in basketGroupedByReward"
        :key="`${item.memberCoverageId}-${item.rewardId}`"
        :item="item"
      />

      <div v-if="basket.length > 0" class="mt-20 space-y-20">
        <p class="font-semibold text-base border-b pb-10">
          {{ $t('rewards.reward_confirmation.sending_to') }}
        </p>

        <div class="space-y-20" :class="{ 'border-b pb-20': usesPoints }">
          <div v-if="containsDigitalGiftCard" class="break-all">
            <p class="font-semibold text-base">
              {{ $t('rewards.reward_confirmation.email') }}
            </p>
            <p>{{ memberInfo.email }}</p>
          </div>

          <div
            v-if="containsPhysicalGiftCard || containsMerchandise"
            class="break-all"
          >
            <p class="font-semibold text-base">
              {{ $t('rewards.reward_confirmation.address') }}
            </p>
            <p>{{ deliveryAddress.streetAddr1 }}</p>
            <p>{{ deliveryAddress.streetAddr2 }}</p>
            <p>
              {{
                `${deliveryAddress.city}, ${deliveryAddress.state} ${deliveryAddress.zip}`
              }}
            </p>
            <!-- <button
              v-if="!suppressMemberEditAddress"
              class="font-semibold text-blue-500 underline mt-12"
              @click="editInfoModal = !editInfoModal"
            >
              {{ $t('rewards.reward_confirmation.edit_address_btn') }}
            </button>
            <button
              v-else
              class="font-semibold text-blue-500 underline mt-12"
              @click="openSupportModal"
            >
              {{ $t('rewards.reward_confirmation.edit_address_btn_disabled') }}
            </button> -->
          </div>
        </div>

        <div
          v-if="usesPoints && !healthActionCompletionId"
          class="rounded-md px-24 py-18 bg-grey-100 space-y-24"
        >
          <p class="font-semibold">
            {{ $t(`rewards.landing.current_balance.title.${currencyType}`) }}
          </p>
          <div class="h-1 bg-grey-300" />
          <div class="flex justify-between">
            <p>{{ $t('rewards.reward_confirmation.rewards_balance') }}</p>
            <p>{{ $t('rewards.points', [oldBalance]) }}</p>
          </div>
          <div class="flex justify-between">
            <p>{{ $t('rewards.reward_confirmation.to_be_redeemed') }}</p>
            <p>{{ $t('rewards.points', [redemptionAmount]) }}</p>
          </div>
          <div class="h-1 bg-grey-300" />
          <div class="flex justify-between">
            <p>{{ $t('rewards.reward_confirmation.remaining_balance') }}</p>
            <p>{{ $t('rewards.points', [newBalance]) }}</p>
          </div>
        </div>
      </div>
    </Card>
    <div v-else class="text-center border border-solid rounded-lg p-6 py-20">
      <i class="fas fa-shopping-cart text-xl" />
      <p>{{ $t('rewards.reward_confirmation.empty_cart') }}</p>
      <p>{{ $t('rewards.reward_confirmation.empty_cart_msg') }}</p>
    </div>

    <div class="mx-auto flex-shrink-0 space-y-20 flex flex-col justify-center">
      <HMButton
        :disabled="isLoading || !basket.length"
        padding="wide"
        @click="placeOrder"
      >
        {{ $t('rewards.reward_confirmation.confirm_btn') }}
      </HMButton>
      <HMButton color="white" outlined padding="wide" @click="onCancel()">
        {{ $t('common.cancel_btn') }}
      </HMButton>
    </div>
    <!-- <Modal :active="editInfoModal" @close="closeEditInfoModal">
      <RewardsEditInfo @close="closeEditInfoModal" />
    </Modal>
    <Modal
      :active="supportModalOpen"
      closeOnBackDrop
      @close="closeSupportModal"
    >
      <ContactSupportModal @close="closeSupportModal" />
    </Modal> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ItemBasket from './ItemBasket'
import _some from 'lodash/some'
// import Modal from '@components/common/Modal'
// import RewardsEditInfo from './RewardsEditInfo'
import { Card } from '@components/common/card/index'
// import { FEATURES } from '@platform-shared/constants'
import rewardsCurrency from '@platform-shared/mixins/rewardsCurrency'
import contactSupportModalMixin from '@platform-shared/mixins/contactSupportModalMixin'
// import ContactSupportModal from '@components/common/ContactSupportModal'

export default {
  components: {
    // RewardsEditInfo,
    ItemBasket,
    // Modal,
    Card,
    // ContactSupportModal
  },
  mixins: [rewardsCurrency, contactSupportModalMixin],
  data() {
    return {
      editInfoModal: false,
      isLoading: false,
    }
  },
  computed: {
    ...mapGetters('rewards', [
      'basket',
      'basketGroupedByReward',
      'deliveryAddress',
      'memberForRedemption',
      'availablePoints',
      'remainingMoney',
    ]),
    ...mapGetters('member', ['memberInfo']),
    // ...mapGetters('client', ['hasFeature']),
    containsPhysicalGiftCard() {
      return _some(this.basket, {
        reward: { deliveryForm: 'physical', type: 'GIFT_CARD' },
      })
    },
    containsDigitalGiftCard() {
      return _some(this.basket, {
        reward: { deliveryForm: 'digital', type: 'GIFT_CARD' },
      })
    },
    containsMerchandise() {
      return _some(this.basket, {
        reward: { deliveryForm: 'physical', type: 'MERCHANDISE' },
      })
    },
    healthActionCompletionId() {
      return this.$route.query.hacId
    },
    newBalance() {
      return this.remainingMoney(this.healthActionCompletionId)
    },
    oldBalance() {
      return this.availablePoints
    },
    redemptionAmount() {
      return -Math.abs(
        this.availablePoints -
          this.remainingMoney(this.healthActionCompletionId)
      )
    },
    redeemingMember() {
      return this.memberForRedemption()
    },
    // suppressMemberEditAddress() {
    //   return this.hasFeature(FEATURES.SUPPRESS_MEMBER_EDIT_ADDRESS)
    // },
  },
  methods: {
    ...mapActions('rewards', [
      'redeemCart',
      'invalidateRewards',
      'initRewards',
      'resetBasket',
    ]),
    onSuccess(
      containsPhysicalGiftCard,
      containsDigitalGiftCard,
      containsMerchandise
    ) {
      this.invalidateRewards()
      this.initRewards()
      this.resetBasket()

      this.$router.push({
        name: 'rewards-success',
        params: {
          containsPhysicalGiftCard,
          containsDigitalGiftCard,
          containsMerchandise,
        },
      })
    },
    onCancel() {
      this.resetBasket()
      this.$router.push('/rewards')
    },
    placeOrder() {
      this.isLoading = true
      const {
        containsPhysicalGiftCard,
        containsDigitalGiftCard,
        containsMerchandise,
      } = this

      if (this.basket.length === 0) return
      this.redeemCart(this.healthActionCompletionId)
        .then(() => {
          this.isLoading = false
          this.onSuccess(
            containsPhysicalGiftCard,
            containsDigitalGiftCard,
            containsMerchandise
          )
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    // closeEditInfoModal() {
    //   this.editInfoModal = false
    // },
  },
}
</script>
<style></style>
