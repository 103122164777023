<template>
  <div class="w-full max-w-4xl space-y-20">
    <RewardBoxBanner v-if="showRewardBoxes" />
    <h1 class="h2">{{ $t('rewards.landing.title') }}</h1>
    <Markdown :source="descriptionText" />
    <DigitalRewardsVerificationNotice v-if="requiresEmailVerification" />

    <div v-if="usesRewardRedemption" class="flex-col space-y-20">
      <div class="flex space-x-20">
        <CurrentBalanceCard v-if="hasBasketRewards" class="flex-1" />
        <FamilyRewardsCard v-if="showFamilyRewards" />
      </div>
      <DirectIncentiveBalanceCard
        v-if="hasDirectIncentives"
        v-for="pointSummary in validDirectIncentives"
        :key="
          `${pointSummary.employerIncentiveStrategy}-${pointSummary.healthActionId}`
        "
        :pointSummary="pointSummary"
      />
      <AutoRewardsSetupCard v-if="usesAutoRewards" />
      <HowItWorksCard v-if="usesPoints" />
      <RewardCarouselCard />
    </div>

    <RewardsHistorySection />
  </div>
</template>

<script>
import _get from 'lodash/get'
import { mapGetters } from 'vuex'
import Markdown from '@components/common/Markdown'
import RewardBoxBanner from './reward-boxes/RewardBoxBanner'
import { FEATURES } from '@platform-shared/constants'
import rewardsCurrency from '@platform-shared/mixins/rewardsCurrency'
import CurrentBalanceCard from './CurrentBalanceCard.vue'
import DirectIncentiveBalanceCard from './DirectIncentiveBalanceCard.vue'
import AutoRewardsSetupCard from './AutoRewardsSetupCard.vue'
import HowItWorksCard from './HowItWorksCard.vue'
import RewardCarouselCard from './RewardCarouselCard.vue'
import FamilyRewardsCard from './FamilyRewardsCard.vue'
import RewardsHistorySection from './RewardsHistorySection.vue'
import DigitalRewardsVerificationNotice from './email-verification/DigitalRewardsVerificationNotice.vue'

export default {
  components: {
    Markdown,
    RewardBoxBanner,
    CurrentBalanceCard,
    DirectIncentiveBalanceCard,
    AutoRewardsSetupCard,
    HowItWorksCard,
    RewardCarouselCard,
    FamilyRewardsCard,
    RewardsHistorySection,
    DigitalRewardsVerificationNotice,
  },
  mixins: [rewardsCurrency],
  computed: {
    ...mapGetters('rewards', [
      'currentRewardsSummary',
      'rewardBoxesAvailable',
      'showFamilyRewards',
      'usesAutoRewards',
      'usesRewardRedemption',
      'validDirectIncentives',
      'hasBasketRewards',
      'hasDirectIncentives',
    ]),
    ...mapGetters('client', ['hasFeature', 'lingo']),
    ...mapGetters('member', ['requiresEmailVerification']),
    descriptionText() {
      const hasHomeFeature = this.hasFeature(FEATURES.HOME)
      const altRewardProgramText = _get(
        this.lingo,
        'altRewardProgramDescription'
      )
      if (altRewardProgramText) return altRewardProgramText
      else if (hasHomeFeature) {
        return this.$t(`rewards.landing.each_description.${this.currencyType}`)
      } else return ''
    },

    showRewardBoxes() {
      const { availableTokens = 0 } = this.currentRewardsSummary

      return (
        this.hasFeature(FEATURES.REWARD_BOXES) &&
        availableTokens > 0 &&
        this.rewardBoxesAvailable.length > 0
      )
    },
  },
}
</script>

<style></style>
